var searchOpen = false;

$("#btnSearch").click(function() {
  $(".search-navbar-collapse").fadeToggle(250);
  searchOpen = !searchOpen;
  if (window.innerWidth >= 991) {
    $(".search-navbar-collapse").css("display", "grid");
  }
  $(this)
    .find(".ico-plus")
    .toggle();
  $(this)
    .find(".ico-search-circ")
    .toggle();
  $("body").toggleClass("no-scroll");
  if ($("body").hasClass("no-scroll")) {
    document.querySelector(".ais-SearchBox-input").focus();
    document.querySelector(".ais-SearchBox-input").select();
  }

  search.setUiState({
    vwGlobalSiteSearch: {
      query: "",
    },
  });
});

$(window).resize(function() {
  if (window.innerWidth <= 991) {
    $(".search-navbar-collapse").show();
  } else if (searchOpen === false) {
    $(".search-navbar-collapse").hide();
  }
});

$("#menustate").click(function() {
  $("body").toggleClass("no-scroll");

  console.log("test");
  search.setUiState({
    vwGlobalSiteSearch: {
      query: "",
    },
  });
});
